import React from 'react';
import { useSelector } from 'react-redux';
import { useExperiments } from '@wix/yoshi-flow-editor';

import { selectGroup } from 'store/selectors';
import { useRichContent } from 'common/utils/ricos';
import { RicosViewer } from 'common/components/Ricos/RicosViewer';

import { CardContent } from 'wui/CardContent';

import { GROUPS_APP_DEFINITION_ID } from '../../../../../../constants';

interface IProps {
  groupId: string;
}

export function GroupDescriptionViewer(props: IProps) {
  const { groupId } = props;

  const group = useSelector(selectGroup(groupId));

  const description = useRichContent(group.description);

  return (
    <CardContent>
      <RicosViewer
        data-hook="group-description-viewer-ricos"
        groupId={groupId}
        postId={groupId}
        containerId={GROUPS_APP_DEFINITION_ID}
        usage="About"
        contentId="about_group"
        content={description}
      />
    </CardContent>
  );
}

GroupDescriptionViewer.displayName = 'GroupDescriptionViewer';
