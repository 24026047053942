import React from 'react';
import cls from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { PrivacyStatus } from '@wix/ambassador-social-groups-v2-group/types';

import {
  selectGroup,
  selectGroupRestriction,
  selectMember,
} from 'store/selectors';

import { Clock as ClockIcon } from '@wix/wix-ui-icons-common/on-stage';
import { Card } from 'wui/Card';
import { List } from 'wui/List';
import { ListItem } from 'wui/ListItem';
import { ListItemIcon } from 'wui/ListItemIcon';
import { ListItemText } from 'wui/ListItemText';
import { ListItemAvatar } from 'wui/ListItemAvatar';
import { CardHeader } from 'wui/CardHeader';
import { CardContent } from 'wui/CardContent';
import { Avatar } from 'wui/Avatar';

import { useRelativeTimeFormatter } from 'common/hooks';
import { GroupPrivacyIcon } from 'common/components/GroupPrivacyIcon';
import { GroupPrivacyLabel } from 'common/components/GroupPrivacyLabel';
import { GroupVisibilityIcon } from 'common/components/GroupVisibilityIcon';
import { ProfileLink } from 'common/components/ProfileLink';

import { GROUP_INFO_CREATED_BY } from '../dataHooks';

import {
  PrivacyLabels,
  RestrictionLabels,
  VisibilityLabels,
} from './constants';

import classes from './GroupInfoCard.scss';

interface IProps extends React.ComponentProps<typeof Card> {
  groupId: string;
}

export function GroupInfoCard(props: IProps) {
  const { groupId, className, ...rest } = props;

  const { t } = useTranslation();
  const rtf = useRelativeTimeFormatter();

  const group = useSelector(selectGroup(groupId));
  const restrictionType = useSelector(selectGroupRestriction(groupId));
  const author = useSelector(selectMember(group.createdBy as string));

  const createdDate = rtf(group.createdDate as Date);
  const visibility = VisibilityLabels[group.privacyStatus as PrivacyStatus];
  const privacy = PrivacyLabels[group.privacyStatus as PrivacyStatus];
  const restriction = restrictionType
    ? RestrictionLabels[restrictionType]
    : undefined;

  return (
    <Card className={cls(className, classes.root)} {...rest}>
      <CardHeader wired title={t('groups-web.group-info')} />
      <CardContent>
        <List disablePadding>
          <ListItem disableGutters>
            <ListItemIcon className={classes.icon}>
              <GroupPrivacyIcon group={group} />
            </ListItemIcon>
            <ListItemText
              title={<GroupPrivacyLabel group={group} />}
              subtitle={t(restriction || privacy.subtitle)}
              titleProps={{ className: classes.title }}
              subtitleProps={{
                noWrap: false,
                secondary: false,
                className: classes.subtitle,
              }}
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemIcon className={classes.icon}>
              <GroupVisibilityIcon group={group} />
            </ListItemIcon>
            <ListItemText
              title={t(visibility.title)}
              subtitle={t(visibility.subtitle)}
              titleProps={{ className: classes.title }}
              subtitleProps={{
                noWrap: false,
                secondary: false,
                className: classes.subtitle,
              }}
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemIcon className={classes.icon}>
              <ClockIcon />
            </ListItemIcon>
            <ListItemText
              title={createdDate}
              subtitle={t('groups-web.created')}
              titleProps={{
                className: classes.title,
                suppressHydrationWarning: true,
              }}
              subtitleProps={{
                noWrap: false,
                secondary: false,
                className: classes.subtitle,
              }}
            />
          </ListItem>
          <ListItem disableGutters data-hook={GROUP_INFO_CREATED_BY}>
            <ListItemAvatar>
              <ProfileLink profile={author} tabIndex={-1}>
                <Avatar
                  size="small"
                  lettersLimit={2}
                  name={author?.name}
                  src={author?.imageUrl}
                />
              </ProfileLink>
            </ListItemAvatar>
            <ListItemText
              title={<ProfileLink wired profile={author} />}
              subtitle={t('groups-web.createdBy')}
              subtitleProps={{
                noWrap: false,
                secondary: false,
                className: classes.subtitle,
              }}
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
}

GroupInfoCard.displayName = 'GroupInfoCard';
